<template>
  <div class="market">
    <header-bar ref="headerBarRef" :transparent="true" menulx="2" noticeColor="#fff"
      @setLanguage="setLanguageAfter"></header-bar>

    <div class="market-bg">
      <img src="@/assets/img/base/mrket-bg.jpg" style="width: 100%;" />
    </div>
    <!-- 占位 -->
    <div class="market-bg-placeholder">
      <img src="@/assets/img/base/mrket-bg.jpg" style="width: 100%;display: block;" />
    </div>

    <div @click="tolink('Jingcai')">
      <img class="img-jingcai" v-if="$utils.getloc('language') == 'zh'" src="@/assets/img/base/jingcai_tc.jpg">
      <img class="img-jingcai" v-else src="@/assets/img/base/jingcai_en.jpg">
    </div>

    <!-- <div class="swipe">
      <van-swipe :autoplay="3000" indicator-color="#2C8B9F">
        <van-swipe-item v-for="item in banner" :key="item.id">
          <img :src="getimg(item.img)" style="width: 100%;" />
        </van-swipe-item>
      </van-swipe>
    </div> -->

    <div class="title" style="padding-top: 0;">{{ $t('Market.xinxi') }}</div>
    <div class="echarts-block">
      <div id="echarts" class="echarts"></div>
      <div class="text-right q-pb-sm" style="color:#5ac725">({{ $t('Market.chushi') }}：{{ showInitialPrice }})</div>
    </div>
    <div class="coin">
      <div class="coin-item">
        <div class="row items-center justify-center">
          <img src="@/assets/img/logo.png" width="25" />
          <div class="echarts-logo-title q-ml-sm">CCAI</div>
        </div>
        <div class="q-mt-sm text-center" style="color:#5ac725">${{ edsPrice }}</div>
        <div class="coin-btn" @click="tolink('Convert?lx=2')">{{ $t('Market.maichu') }}></div>
      </div>
      <div class="coin-item">
        <div class="text-center">{{ $t('Market.kongtou') }}</div>
        <div class="row justify-between q-mt-xs" style="font-size: 13px;">
          <div>{{ $t('Market.dangqian') }}</div>
          <div class="main-color">{{ sai.sumkt }} CCAI</div>
        </div>
        <div class="row justify-between q-mt-xs" style="font-size: 13px;">
          <div>{{ $t('Market.zuori') }}</div>
          <div class="main-color">{{ sai.jinkt }} CCAI</div>
        </div>
        <div class="row justify-between q-mt-xs" style="font-size: 13px;">
          <div>{{ $t('Market.jinri') }}</div>
          <div class="main-color">{{ sai.zuokt }} CCAI</div>
        </div>
      </div>
      <div class="coin-item full-width" style="margin-top: 15px;">
        <div class="row" style="font-weight: bold;">
          <div>{{ $t('Market.daibixiaohui') }}</div>
        </div>
        <div class="row justify-between q-mt-md">
          <div>{{ $t('Market.xiaohuizongliang') }}</div>
          <div class="main-color">{{ sai.saixh }}</div>
        </div>
        <!-- <div class="row justify-between q-mt-sm">
          <div>{{ $t('Market.chubirenshu') }}</div>
          <div class="main-color">{{ sai.saicount }}</div>
        </div> -->
        <div class="row justify-between q-mt-sm">
          <div>{{ $t('Market.faxingzongliang') }}</div>
          <div class="main-color">{{ showTotalSupply }}</div>
        </div>
        <div class="row justify-between q-mt-sm">
          <div>CCAI持币地址</div>
          <div class="main-color">{{ holdAddress }}</div>
        </div>
      </div>
    </div>

    <!-- <pledge /> -->

    <div class="title row items-center justify-between">
      <div>{{ $t('Market.jiedian') }}</div>
      <!-- <div class="down">
				<div v-if="ishomeJiedian == 1">
					<van-count-down :time="downTime" v-if="downTime > 0" @finish="getConformity">
						<template #default="timeData">
							<div class="down-wrap">
								{{ $t('Home.down') }}：
								<span class="block">{{ timeData.days }} {{ $t('Home.day') }}</span>
								<span class="block q-ml-xs">{{ timeData.hours }} {{ $t('Home.hours') }}</span>
								<span class="block q-ml-xs">{{ timeData.minutes  }} {{ $t('Home.minutes') }}</span>
							</div>
						</template>
</van-count-down>
<div class="down-wrap" v-else>
  <div class="text-right">
    <div>{{ $t('Home.qianggouzhong') }}</div>
    <div>2024.7.7 - 2024.7.15</div>
  </div>
</div>
</div>
<div class="down-wrap" v-else>{{ $t('Home.jieshu') }}</div>
</div> -->
    </div>
    <!-- <div v-if="qgtime > 0" class="q-mt-sm">
      <van-count-down :time="qgtime">
				<template #default="timeData">
					<div class="down-wrap">
						<span class="block">{{ timeData.days }} {{ $t('Home.day') }}</span>
						<span class="block q-ml-xs">{{ timeData.hours }} {{ $t('Home.hours') }}</span>
						<span class="block q-ml-xs">{{ timeData.minutes  }} {{ $t('Home.minutes') }}</span>
						<span class="block q-ml-xs">{{ timeData.seconds  }} {{ $t('Home.seconds') }}</span>
					</div>
				</template>
			</van-count-down>
    </div> -->
    <!-- <div class="node">
      <div class="node-item" v-for="item in ulevelList" :key="item.id">
        <div class="node-header">
          <img src="@/assets/img/base/node.png" />
          {{ item.text }}
        </div>
        <div class="node-content">
          <div class="row justify-between">
            <div>
              <div class="node-value main-color">{{ item.price }} USDT</div>
              <div class="node-label">{{ $t('Market.jine') }}</div>
            </div>
            <div class="text-right">
              <div class="node-value main-color">{{ item.suanli }}</div>
              <div class="node-label">{{ $t('Market.suanli') }}</div>
            </div>
          </div>
        </div>
        <div class="node-btn" :class="{'disable-btn' : ishomeJiedian == 0 || downTime < 0}" @click="buyNode(item.id)">
          {{ $t('Home.submit') }}
        </div>
      </div>
    </div> -->

    <NodeGoods />

    <check-register @refresh="onRefresh"></check-register>

    <tab-bar active="0" ref="tabbarRef"></tab-bar>
  </div>
</template>

<script>
import TabBar from '@/components/TabBar'
import HeaderBar from '@/components/HeaderBar'
import CheckRegister from "@/components/CheckRegister"
import { CountDown, Swipe, SwipeItem } from 'vant';
// import Pledge from './components/Pledge'
import NodeGoods from '@/views/node/components/NodeGoods'

import * as echarts from 'echarts';
import { ref } from 'vue';
import Web3Helper from "../../assets/js/web3helper.js";

export default {
  name: '',
  components: {
    // Pledge,
    NodeGoods,
    TabBar,
    HeaderBar,
    CheckRegister,
    [CountDown.name]: CountDown,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  setup() {
    return {
      us: ref(null),
      edsPrice: ref(0),
      showInitialPrice: ref(""),
      showTotalSupply: ref(""),
      holdAddress: ref(""),
      myChart: null,
      dateList: ref([]),
      priceList: ref([]),

      ulevelList: ref([]),
      ulevel: ref({}),
      downTime: ref(0),
      ishomeJiedian: ref(0),

      banner: ref([]),

      sai: ref({}),

      qgtime: ref(0)
    }
  },
  unmounted() {
    if (this.myChart) {
      this.myChart.dispose()
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getBanner()
    let loginCount = this.$utils.getloc("loginCount")
    if (loginCount == 1) {
      this.loadData()
    }
  },
  methods: {
    onRefresh() {
      this.us = JSON.parse(this.$utils.getloc("us"))
      this.loadData()
    },
    loadData() {
      this.getPriceList()
      this.getConformity()
      this.getSai()
      this.getEdsPrice()
    },
    setLanguageAfter() {
      this.$refs.tabbarRef.reSet()
      this.getConformity()
    },
    getimg: function (img) {
      if (img != "") {
        // console.log(img)
        return this.$config.send_url + "Upload/" + img;
      }
    },
    tolink(path) {
      this.$router.push(path)
    },
    getEdsPrice() {
      let _this = this
      _this.$request.post(
        "api/WalletsZhuanhuan/GetEdsPrice",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              message: res.data.msg,
            });
          }
          let data = res.data.data
          _this.edsPrice = data.edsPrice
          _this.showInitialPrice = data.showInitialPrice
          _this.showTotalSupply = data.showTotalSupply
          _this.holdAddress = data.holdAddress
        }
      )
    },
    getBanner() {
      let _this = this
      _this.$request.post(
        "Api/Slide/List", {
        lx: 1
      },
        (res) => {
          if (res.data.code == 100) {
            let data = res.data.data
            _this.banner = data
            // console.log(data)
          }
        }
      )
    },
    getSai() {
      let _this = this
      _this.$request.post(
        "api/Users/GetSai",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 100) {
            let data = res.data.data
            _this.sai = data
          }
        }
      )
    },
    getPriceList() {
      let _this = this
      _this.$request.post(
        "api/EdsKline/PriceList",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          pageSize: 60,
          pageIndex: 1,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              message: res.data.msg,
            });
          }
          let data = res.data.data.reverse()
          data.forEach(item => {
            _this.dateList.push(item.cdate)
            _this.priceList.push(item.price)
          })
          _this.initEcharts()
        }
      )
    },
    initEcharts() {
      let _this = this
      this.myChart = echarts.init(document.getElementById('echarts'))
      let option = {
        grid: {
          left: "2%",
          right: "5%",
          top: "12%",
          bottom: "5%",
          containLabel: true
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: _this.dateList
        },
        yAxis: {
          type: 'value',
          min: 0.01
        },
        series: [
          {
            data: _this.priceList,
            type: 'line',
            lineStyle: {
              width: 0.8,
              color: '#2C8B9F'
            },
            itemStyle: {
              color: '#2C8B9F'
            },
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#2C8B9F'
                },
                {
                  offset: 1,
                  color: '#CDEDFC'
                }
              ])
            }
          }
        ]
      };
      this.myChart.setOption(option)
    },
    getConformity() {
      let _this = this
      _this.$request.post(
        "api/Users/Conformity",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          lx: 1,
          sid: 10
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              message: res.data.msg,
            });
            return
          }
          let data = res.data.data
          console.log(data)
          let language = this.$utils.getloc("language")
          // 选择节点
          let jdList = data.jdList
          jdList.forEach(item => {
            if (language == 'zh') {
              item.text = item.nameZh
            } else if (language == 'kor') {
              item.text = item.nameKor
            } else if (language == 'may') {
              item.text = item.nameMay
            } else if (language == 'vie') {
              item.text = item.nameVie
            } else if (language == 'th') {
              item.text = item.nameTh
            } else {
              item.text = item.nameEn
            }
            item.value = item.id
          })
          _this.ulevelList = jdList
          if (jdList.length > 0) {
            if (_this.us) {
              if (_this.us.ulevel > 3) {
                _this.ulevel = jdList[jdList.length - 1]
              } else {
                _this.ulevel = jdList[_this.us.ulevel]
              }
            } else {
              _this.ulevel = jdList[0]
            }
          }
          let time = data.edate
          let cdate = new Date().getTime()
          let edate = new Date(time).getTime()
          _this.downTime = edate - cdate
          _this.ishomeJiedian = data.ishomeJiedian
          _this.qgtime = new Date('2024-07-15 00:00').getTime() - cdate
        }
      )
    },
    // 购买节点
    buyNode(jid) {
      let _this = this
      if (this.ishomeJiedian == 0 || this.downTime < 0) return
      this.$q.dialog({
        title: _this.$t('Base.tishi'),
        message: _this.$t('Home.message2'),
        ok: {
          label: _this.$t('Base.queding'),
          flat: true,
        },
        cancel: {
          label: _this.$t('Base.quxiao'),
          flat: true,
        },
        persistent: true,
      }).onOk(() => {
        let web3Helper = new Web3Helper();
        this.$q.loading.show({
          message: _this.$t('Home.message3')
        })
        web3Helper.getSign().then((signStr) => {
          _this.$request.post(
            "api/JiedianUser/Buy",
            {
              token: _this.$utils.getloc("token"),
              userid: _this.us.userid,
              jid,
              signStr: signStr
            },
            (res) => {
              _this.$q.loading.hide();
              _this.$q.dialog({
                message: res.data.msg,
              });
            }
          )
        }).catch(() => {
          _this.$q.loading.hide();
        })
      })
    }
  }
}
</script>

<style scoped>
.market {
  position: relative;
  padding: 0 0 10px;
}

.market-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.market-bg-placeholder {
  margin-top: -200px;
  opacity: 0;
}

.market :deep(.address) {
  color: #fff;
}

.market :deep(.header-bar) {
  position: relative;
  z-index: 1;
}

.title {
  padding: 20px 15px 0;
  font-weight: bold;
  font-size: 16px;
}

.coin {
  margin: 15px 15px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.coin-item {
  width: 48%;
  padding: 10px;
  border-radius: 6px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.echarts-block {
  margin: 10px 15px 0;
  padding: 0 15px;
  border-radius: 8px;
  background-color: #fff;
}

.echarts {
  width: 100%;
  height: 300px;
}

.exchanges {
  margin: 20px 15px;
  padding: 15px;
  border-radius: 8px;
  background-color: #fff;
}

.btn {
  margin-top: 15px;
  width: 48%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-weight: bold;
}

.btn-wrap {
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buy-btn {
  background-color: #5ac725;
}

.sell-btn {
  background-color: #f56c6c;
}

.concise {
  margin-top: -60px;
  position: relative;
}

.concise-bg {
  width: 100%;
}

.concise-content {
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
}

.concise-title {
  text-align: center;
  font-weight: 900;
  font-size: 18px;
}

.concise-title span {
  padding: 0 6px;
  display: inline-block;
  color: #fff;
  background-image: linear-gradient(247deg, #f736ff 0%, #f736ff 100%);
  border-radius: 6px;
}

.concise-title-anima {
  margin-top: 20px;
  text-align: center;
  letter-spacing: 0;
  font-weight: Bold;
  background-position: center bottom;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  color: #ffffff;
  animation: titleAnimation 15s ease infinite;
  background-image: url('~@/assets/img/base/hero-text1.jpeg');
  font-size: 32px;
  font-weight: 900;
}

@keyframes titleAnimation {
  0% {
    background-position: center 25%;
  }

  50% {
    background-position: center 50%;
  }

  100% {
    background-position: center 25%;
  }
}

.concise-label {
  padding: 18px 0;
  margin-top: 20px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: 13px;
  background: url('~@/assets/img/base/tip.svg') no-repeat;
  background-size: 100% 100%;
}

.concise-row {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
}

.concise-row>div {
  font-size: 14px;
  font-weight: 800;
  ;
}

.coin-btn {
  margin-top: 10px;
  padding-top: 10px;
  flex: 1;
  text-align: center;
  color: #ff7a4d;
  font-size: 12px;
  border-top: 1px dashed rgba(202, 197, 197, .4);
}

.node {
  margin-top: 15px;
  padding: 0 15px;
}

.node-item {
  margin-bottom: 10px;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.node-header {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.node-header img {
  margin-right: 10px;
  width: 24px;
}

.node-content {
  border-top: 1px dashed rgba(202, 197, 197, .4);
  margin-top: 10px;
  padding-top: 10px;
}

.node-value {
  font-weight: bold;
  font-size: 13px;
}

.node-label {
  color: gray;
  font-size: 12px;
}

.node-btn {
  margin: 15px 0 10px;
  padding: 0 10px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99rem;
  color: #fff;
  background-color: #2C8B9F;
  font-size: 13px;
  word-break: break-all;
  text-align: center;
}

.down-wrap {
  display: flex;
  color: #e02020;
  font-size: 14px;
  font-weight: bold;
  justify-content: center;
}

.disable-btn {
  opacity: .4;
}

.swipe {
  margin-top: 10px;
  padding: 0 15px;
}

.swipe img {
  border-radius: 10px;
}

.img-jingcai {
  width: 100%;
  padding: 15px 0;
}
</style>