<template>
  <q-dialog v-model="show" persistent>
    <q-card style="min-width: 350px">
      <q-card-section>
        <div class="text-body1">{{ $t('CheckRegister.tuijianren') }}</div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <q-input dense v-model="reAddress" :placeholder="$t('CheckRegister.placeholder')" />
      </q-card-section>
      <q-card-actions align="right" class="text-primary">
        <q-btn flat :label="$t('CheckRegister.register')" @click="register" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref } from 'vue'
import Web3Helper from "../assets/js/web3helper.js";

export default {
  name: '',
  components: {},
  props: {
    autoShowRegisterL: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    return {
      show: ref(false),
      web3Helper: ref({}),
      reAddress: ref(""),
      address: ref(""),
      us: ref(null)
    }
  },
  created() {
    if (this.$route.query.reAddress) {
      this.reAddress = this.$route.query.reAddress;
    }
    this.getAddress()
  },
  methods: {
    getAddress() {
      let _this = this;
      _this.web3Helper = new Web3Helper();
      _this.web3Helper.getAddress().then((address) => {
        _this.address = address;
        let loginCount = _this.$utils.getloc("loginCount")
        if (loginCount == 0 || !loginCount) {
          _this.login();
        }
      }, (err) => {
        alert(_this.$t('CheckRegister.addressErr') + err.message);
      });
    },
    // 签名
    signature() {
      let _this = this
      _this.web3Helper.getSign().then((signStr) => {
        _this.$request.post(
          "api/Users/UpdateSign",
          {
            token: _this.$utils.getloc("token"),
            userid: _this.address,
            signStr
          },
          (res) => {
            if (res.data.code == 0) {
              _this.$q.dialog({
                message: res.data.msg
              })
              return
            }
          }
        )
      })
    },
    login() {
      let _this = this;
      _this.web3Helper.getSign().then((signStr) => {
        _this.$request.post(
          "Api/Users/Login", 
          {
            address: _this.address,
            signStr: signStr
          },
          (res) => {
            if (res.data.code == 0) {
              _this.$q.dialog({
                message: res.data.msg
              })
              return
            }
            if (res.data.code == -1) {
              _this.show = true;
              return;
            }
            let data = res.data.data;
            _this.$utils.setloc("token", data.token);
            _this.$utils.setloc("us", JSON.stringify(data.users));
            this.$utils.setloc('loginCount', 1)
            _this.$emit("refresh")

            if(data.newSign == 1) {
              _this.signature()
            }
          }
        );
      })
    },
    register() {
      let _this = this;
      let cacheToken = Math.random();
      try {
        _this.web3Helper.getSign().then((signStr) => {
          _this.$request.post(
            "api/Users/Register", {
            cacheToken: cacheToken,
            recode: _this.reAddress,
            address: _this.address,
            signStr: signStr
          },
          (res) => {
            if (res.data.code == 0) {
              _this.$q.dialog({
                message: res.data.msg
              })
              return
            }
            _this.show = false
            // _this.login()
            
            let data = res.data.data;
            _this.$utils.setloc("token", data.token);
            _this.$utils.setloc("us", JSON.stringify(data.users));
            this.$utils.setloc('loginCount', 1)
            _this.$emit("refresh")

            if(data.newSign == 1) {
              _this.signature()
            }
            
          });
        }).catch((error) => {
          alert(`error:${error}`);
        })
      } catch (error) {
        alert(`error:${error}`);
      }
    },
  }
}
</script>

<style scoped></style>