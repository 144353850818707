<template>
    <div class="node">
        <div class="node-item" v-for="(item, index) in list" :key="index">
            <div class="node-header">
                <div class="node-header-left">
                    <img src="@/assets/img/base/node.png" />
                    <div style="margin-right: 5px;">{{ item.name }}</div>
                </div>
                <div class="node-header-right">
                    <q-linear-progress stripe size="20px" :value="item.progress / 100" color="secondary">
                        <div class="absolute-full flex flex-center">
                            <q-badge color="white" text-color="secondary" :label="`${item.progress}%`" />
                        </div>
                    </q-linear-progress>
                </div>
            </div>
            <div class="node-content">
                <div class="row justify-between">
                    <div>
                        <div class="node-value main-color">{{ item.price }} USDT ×{{ item.bei }}倍</div>
                        <div class="node-label"></div>
                    </div>
                    <div class="text-right">
                        <div class="node-value main-color">{{ item.suanli }}</div>
                        <div class="node-label">日收益{{ item.shouyiDayBiliMin }}% ~ {{ item.shouyiDayBiliMax }}%</div>
                    </div>
                </div>
                <div class="row justify-between">
                    <div>
                        <div class="node-value main-color">节点权益{{ item.song1Wai + item.song2Wai }} CCAI</div>
                        <!-- <div class="node-label">{{item.song1Wai}}(分{{ item.song1WaiMonth }}月) / {{ item.song2Wai }}(分{{ item.song2WaiMonth }}月)</div> -->
                    </div>
                    <div class="text-right">
                        <div class="node-value main-color">{{ item.suanli }}</div>
                        <div class="node-label"></div>
                    </div>
                </div>
            </div>
            <div class="node-btn" @click="submit(item.id)">
                购买
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { Picker, Popup } from 'vant';
import Web3Helper from "@/assets/js/web3helper.js";

export default {
    components: {
        [Picker.name]: Picker,
        [Popup.name]: Popup,
    },
    setup() {
        return {
            us: ref(null),
            list: ref([])
        }
    },
    mounted() {
        this.us = JSON.parse(this.$utils.getloc("us"))
        this.getdata()
    },
    methods: {
        getdata() {
            let _this = this
            _this.$request.post(
                "api/NodeGoods/List",
                {
                    token: _this.$utils.getloc("token"),
                    userid: _this.us.userid,
                    pageIndex: 1,
                    pageSize: 10
                },
                (res) => {
                    console.log(res)
                    if (res.data.code == 100) {
                        let data = res.data.data
                        console.log(data)
                        _this.list = data
                    }
                }
            )
        },
        submit(nodeGoodsId) {
            let _this = this
            _this.$q.dialog({
                title: "提示",
                message: "确定要购买吗？",
                ok: {
                    label: "确定",
                    flat: true,
                },
                cancel: {
                    label: "取消",
                    flat: true,
                },
                persistent: true,
            }).onOk(() => {
                _this.$q.loading.show({
                    message: "购买中..."
                })
                let web3Helper = new Web3Helper();
                web3Helper.getSign().then((signStr) => {
                    _this.$request.post(
                        "api/NodeOrder/Buy",
                        {
                            token: _this.$utils.getloc("token"),
                            userid: _this.us.userid,
                            address: _this.us.address,
                            signStr,
                            nodeGoodsId
                        },
                        (res) => {
                            _this.$q.loading.hide();
                            _this.$q.dialog({
                                message: res.data.msg,
                            });
                        }
                    )
                })
            })
        }
    }
}
</script>

<style scoped>
.node {
    margin-top: 15px;
    padding: 0 15px;
}

.node-item {
    margin-bottom: 10px;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.node-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
}

.node-header-left img {
    margin-right: 10px;
    width: 24px;
}

.node-header-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    font-size: 13px;
}

.node-header-right{
    width: 150px;
    display: flex;
    align-items: center;
}

.node-content {
    border-top: 1px dashed rgba(202, 197, 197, .4);
    margin-top: 10px;
    padding-top: 10px;
}

.node-value {
    font-weight: bold;
    font-size: 13px;
}

.node-label {
    color: gray;
    font-size: 12px;
}

.node-btn {
    margin: 15px 0 10px;
    padding: 0 10px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 99rem;
    color: #fff;
    background-color: #2C8B9F;
    font-size: 13px;
    word-break: break-all;
    text-align: center;
}
</style>